import {BrowserRouter, Routes, Route} from "react-router-dom";
import React, {lazy} from "react";
import AuthorizedRoute from "./components/AuthorizedRoute";
import {SuspenseWrap} from "./components/Header";
import OpenRoute from "./components/OpenRoute";
import QuestionAccordion from "./components/fragments/QuestionAccordion";
import {FragmentMode} from "./interfaces/fragments";

const Login = lazy(() => import("./components/Login"));
const Users = lazy(() => import("./components/Users"));
const Website = lazy(() => import("./components/Website"));
const AdminKB = lazy(() => import("./components/AdminKB"));
const Customers = lazy(() => import("./components/Customers"));
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const KBSettings = lazy(() => import("./components/settings/KBSettings"));
const AssistantSettings = lazy(() => import("./components/settings/AssistantSettings"));
const WidgetSettings = lazy(() => import("./components/settings/WidgetSettings"));
const GeneralSettings = lazy(() => import("./components/settings/GeneralSettings"));
const WebsiteSettings = lazy(() => import("./components/settings/WebsiteSettings"));
const Widget = lazy(() => import("./components/widget/Widget"));
const DeletedContent = lazy(() => import("./components/DeletedContent"));

export const BASEPATH = "/admin";

class Router extends React.Component<{}, {}> {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/deleted" element={<DeletedContent />} />
          <Route path="/" element={<OpenRoute container={<Login />} />} />
          <Route path="/kb/:uniqueId" element={<OpenRoute container={<Website />} />} />
          <Route path="/kb/:uniqueId/:categoryId" element={<OpenRoute container={<Website />} />} />
          <Route path="/kb/:uniqueId/:categoryId/:questionId" element={<OpenRoute container={<Website />} />} />
          <Route path="/widget/faq/:uniqueId/:lang?" element={<OpenRoute container={<Widget />} />} />
          <Route path="/forms/:uniqueId" element={<OpenRoute container={<Widget formsOnly={true} />} />} />
          <Route path="/form/:uniqueId/:formId" element={<OpenRoute container={<Widget formOnly={true} />} />} />
          <Route
            path={`/fragments/question_accordion/:uniqueId/:fragmentId`}
            element={<OpenRoute container={<QuestionAccordion mode={FragmentMode.Iframe} />} />}
          />
          <Route path={"/login"} element={<SuspenseWrap container={<Login />} />} />
          <Route path={`${BASEPATH}/dashboard`} element={<AuthorizedRoute container={<Dashboard />} />} />
          <Route path={`${BASEPATH}/kb/:uniqueId?`} element={<AuthorizedRoute container={<AdminKB />} />} />
          <Route path={`${BASEPATH}/kbsettings`} element={<AuthorizedRoute container={<KBSettings />} />} />
          <Route path={`${BASEPATH}/assistant`} element={<AuthorizedRoute container={<AssistantSettings />} />} />
          <Route path={`${BASEPATH}/widget`} element={<AuthorizedRoute container={<WidgetSettings />} />} />
          <Route path={`${BASEPATH}/website`} element={<AuthorizedRoute container={<WebsiteSettings />} />} />
          <Route path={`${BASEPATH}/users`} element={<AuthorizedRoute container={<Users />} />} />
          <Route path={`${BASEPATH}/settings`} element={<AuthorizedRoute container={<GeneralSettings />} />} />
          <Route path={`${BASEPATH}/customers`} element={<AuthorizedRoute container={<Customers />} />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default Router;
